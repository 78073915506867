import { backgroundQuaternaryLightMode, dark1, white } from '@assets/colors';
import { InstallerContext } from '@auth/utils/authContext';
import React, { useContext } from 'react';
import { Input } from 'storybook-design-system-drapo';
import { FolderContext } from '@folder/utils/FoldersContext';

export default function FormAccountMpr(): JSX.Element {
  const { radiusPx, isThemeDark } = useContext(InstallerContext);
  const { folderDatas, updateFolderDatas } = useContext(FolderContext);

  const arrayInputsDataAccountMpr = ['Numéro de dossier MPR'];

  return (
    <div
      style={{ borderRadius: radiusPx }}
      className="dark:bg-dark-2 bg-white p-[1.5rem] mt-[2rem] dark:text-white text-colorTextLightMode"
    >
      <div className="sb-text-rem-body2">Numéro de dossier MaPrimeRenov</div>
      <div className="flex flex-col space-y-[.875rem]  mt-[.875rem]">
        {arrayInputsDataAccountMpr.map((el) => (
          <div key={el}>
            <Input
              backgroundColor={
                isThemeDark ? dark1 : backgroundQuaternaryLightMode
              }
              color={isThemeDark ? white : dark1}
              label={el}
              onChange={(e) => {
                updateFolderDatas({
                  ...folderDatas,
                  mpr_number: e.target.value,
                });
              }}
              value={folderDatas.mpr_number}
              inputType="text"
              dataTestId="id"
              borderRadius={radiusPx}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export { FormAccountMpr };
