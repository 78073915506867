import {
  IFolderDatasType,
  IMaterialDataType,
  IOperationFolderType,
  ISubcontractorDataType,
} from './folderTypes';

export const intialFolderDatas: IFolderDatasType = {
  client: {
    email: '',
    firstname: '',
    gender: 'Monsieur',
    lastname: '',
    phone: '',
    owner_street: '',
    owner_city: '',
    owner_zipcode: '',
  },
  created_at: '',
  files: [
    {
      mandatory: false,
      path: null,
      type: '',
      title: '',
      generated: null,
    },
  ],
  completed: false,
  folder_reference: '',
  from_bulk_import: false,
  front_status: '',
  has_cadastre_step: false,
  has_client: false,
  has_indivision_step: false,
  has_mpr_step: false,
  mpr_number: '',
  has_note_step: false,
  has_fiscal_step: false,
  in_airtable: false,
  menage_joint_ownership: false,
  nb_fiscal: 0,
  number_avis_fiscal_client_1: '',
  number_fiscal_client_1: '',
  operations: [
    {
      operation_reference: '',
      operation_type: '',
      prime_cee_beneficiary: 0,
      mpr_amount: 0,
      mpr_folder_fees: 0,
      operation_name: '',
      slug: '',
      hasMaterialStep: false,
      files: [
        {
          mandatory: false,
          path: null,
          type: '',
          title: '',
          generated: null,
        },
      ],
      subcontractor: { key: '', value: '' },
      material: [{ key: '', value: '' }],
    },
  ],
  status: '',
  subcontractors: [
    {
      id: '',
      need_email: false,
      title: '',
    },
  ],
  total_cee: 0,
  total_fees_cee: 0,
  total_fees_mpr: 0,
  total_mpr: 0,
  work_city: '',
  work_land_register: '',
  work_latitude: '',
  work_longitude: '',
  work_street: '',
  work_zipcode: '',
  is_executive: true,
  executive_code: '',
  energy_class: 'D',
};

export const initialOperationsFolder: IOperationFolderType[] = [
  {
    hasMaterialStep: true,
    operation_reference: '',
    operation_type: '',
    operation_name: '',
    slug: '',
    files: [
      {
        type: 'file_devis',
        path: null,
        mandatory: true,
        title: '',
        generated: null,
      },
    ],
  },
];

export const initialSubcontractorsData = (
  operations: IOperationFolderType[]
) => {
  const datas: ISubcontractorDataType[] = [];

  for (let i = 0; i < operations.length; i += 1) {
    const subObj: ISubcontractorDataType = {
      operation: operations[i].operation_reference,
      action: 'no',
      label: '',
    };
    if (operations[i].subcontractor !== undefined) {
      subObj.action = 'subcontractorId';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subObj.subcontractorId = operations[i].subcontractor.subcontractor_id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subObj.label = operations[i].subcontractor.subcontractor_social_reason;
    }
    datas.push(subObj);
  }

  return datas;
};

export const initialMaterialsData = (operations: IOperationFolderType[]) => {
  const datas: IMaterialDataType[] = [];
  for (let i = 0; i < operations.length; i += 1) {
    const materialObj: IMaterialDataType = {
      operation: operations[i].operation_reference,
      haveId: true,
      referenceMark: '',
      label: '',
    };
    if (operations[i].material !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      materialObj.referenceMark = operations[i].material[0].id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      materialObj.label = operations[i].material[0].title;
    }

    if (!operations[i].hasMaterialStep) {
      materialObj.noStep = true;
    }
    datas.push(materialObj);
  }
  return datas as IMaterialDataType[];
};

export const checkIfMaterialNeeded = (operations: IOperationFolderType[]) => {
  const tab: string[] = [];
  const isFalse = (currentValue: string) => currentValue === 'false';
  for (let i = 0; i < operations.length; i += 1) {
    tab.push(operations[i].hasMaterialStep.toString());
  }
  return !tab.every(isFalse);
};
